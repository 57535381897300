<script setup>
import { onMounted, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';

const bgColor = ref('bg-gray-800 dark:bg-gray-200 hover:bg-gray-700 dark:hover:bg-white text-white dark:text-gray-800 focus:bg-gray-700 dark:focus:bg-white active:bg-gray-900 dark:active:bg-gray-300');

const props = defineProps({
    layout: { type: Object },
});

onMounted(() => {
    var css = usePage().props.primaryButtonCss;
    if (css) {
        bgColor.value = css.bgColor;
    }
})
</script>
<template>
    <button
        class="inline-flex items-center text-xl py-4 px-7 rounded-xl border border-transparent  font-semibold focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 dark:focus:ring-offset-gray-800 transition ease-in-out duration-150 dark:text-white"
        :class="{ [bgColor]: true }">
        <slot />
    </button>
</template>
